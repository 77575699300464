import React from "react"
import { Cloudinary } from "@cloudinary/url-gen"
import { AdvancedImage } from "@cloudinary/react"
import { cloudImage } from "../utils.js"
import * as style from "./ads-ver-two-hero.module.css"

const adsVersionTwoHero = ({ pagedata }) => {
  const heroData = pagedata

  const customCTA =
    heroData.cfAdsServicesTwo.servPageVTwoCta || "Schedule a free consultation"
  const featImg = heroData.featuredImage.node.mediaDetails.file
  const mimeType = heroData.featuredImage.node.mimeType
  const featuredImgUrl = cloudImage(featImg, mimeType, 1920, true)
  const logos = heroData.cfServicesAdsHero.adsSingleHeroLogos

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  return (
    <>
      <div
        className="d-flex align-items-center darkgrey_bg bg_image flex-column justify-items-center mb-0"
        style={{
          backgroundImage: featuredImgUrl,
          height: "100vh",
          minHeight: "600px",
        }}
      >
        <div className="container flex-grow-1">
          <div
            id="hide-row-when-nav-open"
            className="row h-100 align-content-center"
          >
            <span>
              <div className="col-12 color-white animated-headline z_index_top">
                <div className={`animated-headline-wrap`}>
                  <h1 className="new_style">
                    {heroData.cfServicesAdsHero.adsSingleHeroTitle}
                  </h1>
                </div>
                <span
                  className="mt-4 pb-4 d-block header-fade-in"
                  style={{ fontSize: "1.3rem" }}
                >
                  {heroData.cfServicesAdsHero.adsIntroText}
                </span>
              </div>
              <div className="col-12 mt-4 z_index_top header-fade-in">
                <a
                  href="#contactForm"
                  title={customCTA}
                  className={`${style.white_button} d-inline-block`}
                >
                  {customCTA}
                  <img
                    src="/assets/img/arrow-grey.svg"
                    alt="grey arrow icon"
                    className="ms-3"
                  />
                </a>
              </div>
            </span>
          </div>
        </div>
        {logos !== null && (
          <div className="container z_index_top mb-5">
            <div className="row">
              <div
                className={`d-flex align-items-center justify-content-start justify-content-lg-between`}
                style={{
                  flexWrap: "inherit",
                }}
              >
                {logos.map((logo, index) => {
                  let imgUri = logo.indSingleHeroSingleLogo.mediaDetails.file
                  let altText = logo.indSingleHeroSingleLogo.altText

                  const theImage = cld.image(
                    `${process.env.GATSBY_API_FOLDER_URL}${imgUri}`
                  )
                  theImage.format("auto")

                  let imgHeight =
                    logo.indSingleHeroSingleLogo.mediaDetails.height +
                    logo.indSingleHeroSingleLogo.mediaDetails.height * 1.25
                  let imgWidth = logo.indSingleHeroSingleLogo.mediaDetails.width
                  let maxHeight = ""
                  if (imgHeight > imgWidth) {
                    maxHeight = style.serv_ads_client_logo_h
                  }

                  return (
                    <AdvancedImage
                      cldImg={theImage}
                      key={index}
                      alt={altText}
                      className={`${style.serv_ads_client_logo} ${maxHeight} mw-sm-100`}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        )}
        <div className="dark_image_overly"></div>
      </div>
    </>
  )
}

export default adsVersionTwoHero
