import React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import { ParallaxProvider } from "react-scroll-parallax"
import CaseStudiesSlider from "../components/shared/case-studies"
import AdsVersionTwoForm from "../components/ad-pages/ads-ver-two-form"
import AdsVersionTwoHero from "../components/ad-pages/ads-ver-two-hero"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { cloudImage, seoHead } from "../components/utils.js"
import AdsVTwoGatedForm from "../components/ad-pages/ads-gated-doc"
import * as style from "./page-template-targeted-lp-two.module.css"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
        featuredImage {
          node {
            mimeType
            mediaDetails {
              file
            }
          }
        }
        cfAdsServicesTwo {
          servPageVTwoCta
          adsServIntroParagraphs
          adsServHidePartnersBlock
          adsServPartners {
            adsServPartnerName
            adsServPartnerJobTitle
            adsServTheQuote
            adsServTheLogo {
              altText
              mimeType
              mediaDetails {
                file
              }
            }
          }
          adsServIconBlocks {
            adsServTheIconText
            adsServTheIconTitle
            adsServTheIcon {
              altText
              mimeType
              mediaDetails {
                file
              }
            }
          }
          adsServGatedPdfShowSection
          showVideoBlock
          videoUpload {
            mediaItemUrl
          }
          adsServBackgroundImageBehindForm {
            mediaType
            mediaDetails {
              file
            }
          }
          adsServIntroSectionImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
        cfServicesAdsHero {
          adsIntroText
          adsSingleHeroTitle
          adsSingleHeroLogos {
            indSingleHeroSingleLogo {
              altText
              mediaType
              mediaDetails {
                file
                height
                width
              }
            }
          }
        }
        cfSharedCaseStudies {
          sharedCaseStudiesContentSelect
          sharedCaseStudiesClients {
            sharedCaseStudiesClientsCaseStudy {
              ... on WPGraphQL_Page {
                title
                uri
                cfClientsSingleAll {
                  singleClientHeroIntro
                  clientGlobalSharedImage {
                    altText
                    mediaDetails {
                      file
                    }
                    mimeType
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const PageTemplateAdsVTwo = ({ data }) => {
  const page = data.wpgraphql.page

  const showCaseStudies =
    page.cfSharedCaseStudies.sharedCaseStudiesContentSelect || false

  const customCTA =
    page.cfAdsServicesTwo.servPageVTwoCta || "Schedule a free consultation"

  // cloudinary

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  // PDF gate

  const showPdfGate = page.cfAdsServicesTwo.adsServGatedPdfShowSection || false

  // video section

  const showVideo = page.cfAdsServicesTwo.showVideoBlock || false
  let videoUrl = null

  if (showVideo && page.cfAdsServicesTwo.videoUpload !== null) {
    videoUrl = page.cfAdsServicesTwo.videoUpload.mediaItemUrl
  }

  // partners section

  const partnersBlock = page.cfAdsServicesTwo.adsServHidePartnersBlock || false

  let partnersQuotes

  if (partnersBlock !== true) {
    partnersQuotes = page.cfAdsServicesTwo.adsServPartners.map(
      (singleQ, index) => {
        if (singleQ.adsServTheLogo !== null) {
          console.log(index)
          let imgUri = singleQ.adsServTheLogo.mediaDetails.file
          let altText = singleQ.adsServTheLogo.altText

          let theImage = cld.image(
            `${process.env.GATSBY_API_FOLDER_URL}${imgUri}`
          )
          theImage.format("auto")

          return (
            <div
              key={index}
              className="col-12 col-md-6 text-center px-5 mb-5 mb-md-0"
            >
              <span className="h3 d-block" style={{ lineHeight: "1.4" }}>
                "{singleQ.adsServTheQuote}"
              </span>
              <AdvancedImage
                cldImg={theImage}
                alt={altText}
                className="mb-4 pb-2 mw-100 mt-3 mt-md-5"
                style={{ height: "60px" }}
              />
              <span className="d-block" style={{ color: "#f66400" }}>
                {singleQ.adsServPartnerName}
                <br />
                {singleQ.adsServPartnerJobTitle}
              </span>
            </div>
          )
        } else {
          return <></>
        }
      }
    )
  }

  // x3 blocks

  const threeBlocks = page.cfAdsServicesTwo.adsServIconBlocks.map(
    (singleBlock, index) => {
      let theLength = page.cfAdsServicesTwo.adsServIconBlocks.length
      let imgUri = singleBlock.adsServTheIcon.mediaDetails.file
      let altText = singleBlock.adsServTheIcon.altText

      let theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${imgUri}`)
      theImage.format("auto")

      let theBreakPoint = "col-md-4"

      // set column widths
      switch (theLength) {
        case 1:
          theBreakPoint = "col-lg-12"
          break
        case 2:
          theBreakPoint = "col-md-6"
          break
        default:
          theBreakPoint = "col-md-6 col-lg-4"
      }

      return (
        <div key={index} className={`col-12 ${theBreakPoint}`}>
          <AdvancedImage
            cldImg={theImage}
            alt={altText}
            className="mb-4 pb-3 pb-md-5 mw-100"
            style={{ height: "150px" }}
          />
          <h2 className="pb-4">{singleBlock.adsServTheIconTitle}</h2>
          <div
            dangerouslySetInnerHTML={{ __html: singleBlock.adsServTheIconText }}
            className="mb-5 mb-md-0"
          />
        </div>
      )
    }
  )

  // Opening section

  const secOneBgImgSlug =
    page.cfAdsServicesTwo.adsServIntroSectionImage.mediaDetails.file
  const secOneBgImgMime =
    page.cfAdsServicesTwo.adsServIntroSectionImage.mimeType
  const secOneBgImg = cloudImage(secOneBgImgSlug, secOneBgImgMime, 950, true)

  // Form section

  const secFormBgImgSlug =
    page.cfAdsServicesTwo.adsServBackgroundImageBehindForm.mediaDetails.file
  const secFormBgImgMime =
    page.cfAdsServicesTwo.adsServBackgroundImageBehindForm.mimeType
  const secFormBgImg = cloudImage(secFormBgImgSlug, secFormBgImgMime, 950, true)

  return (
    <>
      <Layout>
        <div className="grey_bg color-white">
          <AdsVersionTwoHero pagedata={page} />
          <div
            className="container-fluid"
            style={{
              backgroundImage: "url(/assets/img/bg-dots.svg)",
              backgroundRepeat: "repeat-y",
            }}
          >
            <div className="row">
              <div
                className={`col-12 col-lg-6 pe-lg-5 offset_left ${style.offset_left}`}
              >
                <div
                  className={style.intro_para}
                  dangerouslySetInnerHTML={{
                    __html: page.cfAdsServicesTwo.adsServIntroParagraphs,
                  }}
                />
                <a
                  href="#contactForm"
                  title={customCTA}
                  className={`${style.white_button} mt-5 d-inline-block`}
                >
                  {customCTA}
                  <img
                    src="/assets/img/arrow-grey.svg"
                    alt="grey arrow icon"
                    className="ms-3"
                  />
                </a>
              </div>
              <div
                className="col-12 col-lg-6"
                style={{
                  backgroundImage: secOneBgImg,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          </div>

          {showPdfGate && (
            <div
              style={{
                backgroundImage:
                  "url(https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/c_scale,w_1920/v1/img/AdobeStock_713515539.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div style={{ backgroundColor: "rgba(0,0,0,.5)" }}>
                <div className="container">
                  <div className="row justify-content-between pt-5 pb-5">
                    <div className="col-12 col-xl-6">
                      <div
                        className="p-3 p-xl-5"
                        style={{
                          backgroundColor: "rgba(0,0,0,.5)",
                          color: "white",
                          height: "100%",
                        }}
                      >
                        <span>GenAI</span>
                        <h2 className="pb-4">
                          Free GenAI For Digital Experience eBook
                        </h2>
                        <p>
                          GenAI enables businesses to create intelligent,
                          personalised, and emotionally resonant experiences at
                          an unprecedented scale. By harnessing the power of
                          this tech, we can infuse a sense of empathy,
                          contextual understanding, and dynamic problem-solving
                          capabilities into digital touchpoints. This shift from
                          transactions to relationships marks a new era in
                          customer experience.
                          <br />
                          <br />
                          We are witnessing the dawn of the intelligence age, a
                          pivotal moment that will reshape society, businesses,
                          and governments on a scale comparable to the
                          agricultural and industrial revolutions.
                          <br />
                          <br />
                          This document serves as a guide to help leaders
                          understand and harness the power of AI while
                          navigating the challenges and opportunities that lie
                          ahead.
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-xl-5 mt-5 mt-xl-0">
                      <div
                        className="p-3 p-xl-5"
                        style={{
                          backgroundColor: "rgba(0,0,0,.5)",
                          color: "white",
                          height: "100%",
                        }}
                      >
                        <AdsVTwoGatedForm />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="container">
            <div className="row mt-6 mb-6 pt-3 pb-3 pt-xl-5 gx-0 gx-sm-5 gx-md-6">
              {threeBlocks}
            </div>
          </div>

          {showVideo && videoUrl !== null && (
            <div className="container">
              <div className="row pb-6 justify-content-center">
                <div className="col-12">
                  <video
                    playsInline
                    controls
                    style={{
                      objectFit: "cover",
                      maxWidth: "100%",
                      border: "2px solid white",
                      borderRadius: "7px",
                      padding: "0",
                    }}
                  >
                    <source src={videoUrl} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          )}

          {partnersBlock !== true && (
            <div className="container-fluid">
              <div
                className="row g-0 mb-6 pt-5 justify-content-center"
                style={{
                  paddingLeft: "calc((100vw - 1296px) / 2)",
                  paddingRight: "calc((100vw - 1296px) / 2)",
                  backgroundImage: "url(/assets/img/bg-dots-hor.svg)",
                  backgroundRepeat: "repeat-x",
                }}
              >
                <div className="col-12 mb-5 pb-2">
                  <span
                    className="text-center d-block"
                    style={{ fontSize: "20px" }}
                  >
                    What our partners have to say
                  </span>
                </div>
                {partnersQuotes}
              </div>
            </div>
          )}

          {showCaseStudies === true && (
            <ParallaxProvider>
              <CaseStudiesSlider
                sendata={page.cfSharedCaseStudies.sharedCaseStudiesClients}
              />
            </ParallaxProvider>
          )}

          <div className="container-fluid">
            <div className="row justify-content-center">
              <div
                id="contactForm"
                className="col-12"
                style={{
                  backgroundImage: secFormBgImg,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  minHeight: "500px",
                }}
              ></div>
              <div
                className="col-12 col-sm-10 col-md-6 col-lg-5"
                style={{ marginTop: "-250px" }}
              >
                <AdsVersionTwoForm />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PageTemplateAdsVTwo

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
