import React, { useState } from "react"
import { Link } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form"
import * as s from "./ads-gated-doc.module.css"

const AdsVTwoGatedForm = ({ data }) => {
  // Move below later

  const [verified, setVerified] = useState(false)
  const recaptchaRef = React.createRef()
  const [recap, setRecap] = useState("")

  const onChange = value => {
    console.log(value)
    setRecap(value)
    setVerified(prevVerified => !prevVerified)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // set error classes

  const classInit = `form-control ${s.form_control}`

  const classError = `${classInit} ${s.invalid_feedback_input}`

  // set form state

  const [submitStatus, setSubmitStatus] = useState("initial")

  // set success function

  const subSuccess = () => {
    // set small delay to allow smoother animation
    setTimeout(function () {
      setSubmitStatus("success")
    }, 1000)
  }

  // handle submission

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const onSubmit = data => {
    if (verified) {
      setSubmitStatus("processing")
      console.log(verified)
      //let recapContain = document.getElementById("captchaContainer")

      var coName = data.enqCompany

      if (coName === "") {
        coName = "Not given"
      }

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "gatedDownload",
          enqSubject: "Gated Downloads",
          enqName: data.enqName,
          enqSurName: data.enqSurName,
          enqCompany: coName,
          enqEmail: data.enqEmail,
          enqMIscData: data.enqMIscData,
          "g-recaptcha-response": recap,
        }),
      })
        .then(() => subSuccess())
        .catch(error => setSubmitStatus("initial"))
    } else {
      console.log("Google reCaptcha not set")
      //recapContain.classList.add("captcha_error", "captchaContainer")
    }
  }

  return (
    <>
      <span
        className={
          submitStatus === "success"
            ? `${s.initial_intro_title} ${s.processing_intro_title} ${s.success_intro_title} pb-0 mb-0`
            : "" || submitStatus === "processing"
            ? `${s.initial_intro_title} ${s.processing_intro_title} pb-0 mb-0`
            : "" || submitStatus === "initial"
            ? `${s.initial_intro_title} pb-0 mb-0`
            : `${s.initial_intro_title} pb-0 mb-0`
        }
      >
        Download your copy today, it's FREE!
      </span>

      <form
        onSubmit={handleSubmit(onSubmit)}
        name="gatedDownload"
        method="POST"
        data-netlify="true"
        netlify-honeypot="enqMIscData"
        data-netlify-recaptcha="true"
        action="/thank-you"
      >
        <div
          className={
            submitStatus === "success"
              ? `${s.initial} ${s.processing} ${s.success}`
              : "" || submitStatus === "processing"
              ? `${s.initial} ${s.processing} `
              : "" || submitStatus === "initial"
              ? s.initial
              : s.initial
          }
        >
          <div className="row">
            <div className="col-12 col-xl-6">
              <input
                {...register("enqName", { required: true })}
                type="text"
                placeholder="Your First Name*"
                aria-label=""
                className={
                  errors.enqName
                    ? `${classError} mt-3`
                    : `${classInit} mt-4 mt-md-3`
                }
              />
              {errors.enqName && false && (
                <span
                  className={`${s.invalid_feedback} position-absolute d-block pt-1 form-text`}
                >
                  This field is required
                </span>
              )}
            </div>
            <div className="col-12 col-xl-6">
              <input
                {...register("enqSurName", { required: true })}
                type="text"
                placeholder="Your Second Name*"
                aria-label=""
                className={
                  errors.enqSurName
                    ? `${classError} mt-3`
                    : `${classInit} mt-4 mt-md-3`
                }
              />
              {errors.enqSurName && false && (
                <span
                  className={`${s.invalid_feedback} position-absolute d-block pt-1 form-text`}
                >
                  This field is required
                </span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-xl-6">
              <input
                {...register("enqEmail", {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
                type="text"
                placeholder="Email Address*"
                aria-label=""
                className={
                  errors.enqEmail
                    ? `${classError} mt-2`
                    : `${classInit} mt-4 mt-md-2`
                }
              />
              {errors.enqEmail && false && (
                <span
                  className={`${s.invalid_feedback} position-absolute d-block pt-1 form-text`}
                >
                  This field is requires a valid email address
                </span>
              )}
            </div>
            <div className="col-12 col-xl-6">
              <input
                {...register("enqCompany", { required: false })}
                type="text"
                placeholder="Company"
                aria-label=""
                className={`${classInit} mt-4 mt-md-2`}
              />
            </div>
          </div>

          <div className="d-none">
            <input {...register("enqMIscData", { required: false })} />
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <div
                className={
                  !verified ? `${s.cap_err} d-inline-block` : `d-inline-block`
                }
              >
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
                  size="normal"
                  theme="dark"
                  onChange={onChange}
                />
              </div>
              {/* {!verified &&
                (errors.enqEmail || errors.enqSurName || errors.enqName) && (
                  <span
                    className={`${s.invalid_feedback} position-absolute d-block pt-1 form-text`}
                  >
                    Please confirm you are not a robot.
                  </span>
                )} */}
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <button
              type="submit"
              id="gatedDownload"
              className={
                submitStatus === "success"
                  ? `${s.mfSub} ${s.processingBtn}  ${s.successBtn}`
                  : "" || submitStatus === "processing"
                  ? `${s.mfSub} ${s.processingBtn}`
                  : "" || submitStatus === "initial"
                  ? `${s.mfSub} ${s.send_icon} mt-4`
                  : `${s.mfSub} ${s.send_icon} mt-4`
              }
            >
              {submitStatus === "success" ? (
                <>
                  <span>
                    <img
                      src="/assets/img/icon_success.svg"
                      alt=""
                      style={{ width: "32px" }}
                    />
                  </span>
                </>
              ) : "" || submitStatus === "processing" ? (
                <span>
                  <img
                    src="/assets/img/submit_black.svg"
                    alt=""
                    style={{ width: "24px" }}
                  />
                </span>
              ) : "" || submitStatus === "initial" ? (
                <span>
                  <img
                    src="/assets/img/submit_black.svg"
                    alt=""
                    style={{
                      width: "24px",
                      marginRight: "10px",
                    }}
                  />{" "}
                  Download now
                </span>
              ) : (
                <span>
                  <img
                    src="/assets/img/submit_black.svg"
                    alt=""
                    style={{
                      width: "24px",
                      marginRight: "10px",
                    }}
                  />{" "}
                  Download now
                </span>
              )}
            </button>

            <div
              className={
                submitStatus === "success"
                  ? `${s.success_message_init} ${s.success_message} text-center`
                  : `${s.success_message_init}`
              }
            >
              <h3 className="text-center mt-4 ga_form_submit">
                Download Available
              </h3>
              <p className="text-center mb-4">
                Thank you, please use the button below to get your copy.
              </p>
              <div>
                <a
                  href="https://waracle.com/dl/Waracle-GenAI-for-digital-experiences-2024.pdf"
                  title="download"
                  target="_blank"
                  className={`${s.white_button} mb-5 d-inline-block`}
                >
                  {/* <span style={{ fontSize: "18px" }}>Download</span> */}
                  <img
                    src="/assets/img/file-download-solid.svg"
                    className="d-block"
                    style={{ width: "50px" }}
                  />
                </a>
              </div>
              <Link
                to="/"
                title="Back to contact form"
                className="ani_grey_arrow"
                style={{ marginLeft: "-40px" }}
              >
                Back to home page
              </Link>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div
              style={{ marginTop: "-15px" }}
              className={
                submitStatus === "success"
                  ? `${s.ts_cs} ${s.initial_tc} ${s.processing_tc} ${s.success}`
                  : "" || submitStatus === "processing"
                  ? `${s.ts_cs} ${s.initial_tc} ${s.processing_tc}`
                  : "" || submitStatus === "initial"
                  ? `${s.ts_cs} ${s.initial_tc}`
                  : `${s.ts_cs} ${s.initial_tc}`
              }
            >
              By submitting this form you agree to the storing and processing of
              your personal data by Waracle as described in the Project enquiry
              section of the{" "}
              <a
                href="/privacy-policy/"
                target="_blank"
                style={{ zIndex: "9999", position: "relative" }}
              >
                {" "}
                Privacy policy
              </a>
              .
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default AdsVTwoGatedForm
